import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 545px)')
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  private userSub: Subscription;
  isLoggedIn = false;

  constructor(private breakpointObserver: BreakpointObserver, private loginService: LoginService) { }
  ngOnInit() {
    this.userSub = this.loginService.user.subscribe(user => {
      this.isLoggedIn = !!user;
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  onLogout() {
    this.loginService.logout()
  }
}
