<div class="header-container">
  <h1 style="font-size: 3.5rem">Industry 4.0 Data</h1>
  <h2 style="font-size: 1.8rem">
    Real time collection, management and retrieval
  </h2>
</div>
<div fxLayout="column wrap" class="center">
  <div class="table-container">
    <div>
      <mat-label style="margin-right: 25px">Filter</mat-label>
      <mat-form-field #machineStatus style="margin-right: 25px">
        <mat-label>Machine Status</mat-label>
        <mat-select
          [(ngModel)]="mStatus"
          (selectionChange)="applyFilter($event.value)"
        >
          <mat-option value="On">On</mat-option>
          <mat-option value="Off">Off</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field #machineSensors style="margin-right: 25px">
        <mat-label>Sensors</mat-label>
        <mat-select
          [formControl]="sensors"
          multiple
          (selectionChange)="applyFilter($event.value)"
        >
          <mat-select-trigger>
            {{ sensors.value ? sensors.value[0] : "" }}
            <span
              *ngIf="sensors.value?.length > 1"
              class="example-additional-selection"
            >
              (+{{ sensors.value.length - 1 }}
              {{ sensors.value?.length === 2 ? "other" : "others" }})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let sensor of sensorList" [value]="sensor">{{
            sensor
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <button mat-raised-button (click)="getEntities()">Get Machines</button> -->
    </div>
    <div class="example-loading-shade" *ngIf="!dataAvailable">
      <mat-spinner *ngIf="dataAvailable"></mat-spinner>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="dataSource"
        class="example-table"
        matSort
        matSortActive="created"
        matSortDisableClear
        matSortDirection="desc"
        (matSortChange)="resetPaging()"
      >
        <!-- Number Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
          <td mat-cell *matCellDef="let row">{{ row.id }}</td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="sensor">
          <th mat-header-cell *matHeaderCellDef>Sensors</th>
          <td mat-cell *matCellDef="let row">{{ row.sensor }}</td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{ row.status }}</td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Position
          </th>
          <td mat-cell *matCellDef="let row">{{ row.position }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [length]="resultsLength" [pageSize]="5"></mat-paginator>
    </div>
  </div>
</div>
