import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { environment } from '../environments/environment';

export interface MachineData {
  id: string;
  status: string;
  sensor: string;
  position: string;
}

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket: any;
  machineData: MachineData[] = [];//MatTableDataSource<MachineData>;
  waiting = true;
  isError = false;
  dataAvailable = false;
  entityAttribute: MachineData = {
    id: 'id',
    status: 'status',
    sensor: 'sensors',
    position: 'location',
  };
  machineDataChanged = new Subject<MachineData[]>();
  constructor(private http: HttpClient) {
    this.getEntities();
  }
  setupSocketConnection() {
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('ngsildbroker-notification', (notification: any) => {
      console.log(notification)
      for (const mdata of notification.data) {
        if (mdata != null) {
          const index = this.machineData.findIndex((obj => obj.id === mdata.id));
          this.machineData[index].status = mdata.status.value;
          this.machineDataChanged.next(this.machineData)
        }
      }
    });
  }

  getEntities(): void {
    let brokerUrl = 'https://machine-monitoring.mdr.iotcrawler.eu/ngsi-ld/v1/entities/?type=Machine';
    console.log(brokerUrl);
    this.http
      .get(brokerUrl)
      .pipe(
        catchError((error) => {
          this.waiting = false;
          this.isError = true;
          return throwError(error);
        })
      )
      .subscribe((entities) => {
        // console.log('got device data', entities);
        if (entities) {
          console.log('Got device data', entities);
          for (const key in entities) {
            if (key != null) {
              const entity = entities[key];
              this.machineData.push({
                id: entity[this.entityAttribute.id],
                status: entity[this.entityAttribute.status].value,
                sensor: entity[this.entityAttribute.sensor].value,
                position:
                  entity[this.entityAttribute.position].value.coordinates,
              });
            }
          }
          this.machineDataChanged.next(this.machineData);
          this.dataAvailable = true;
        } else {
          console.error('HTTP error');
          this.isError = true;
        }
        this.waiting = false;
      });
  }

}
