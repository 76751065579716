import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from './user.model';

@Injectable({ providedIn: 'root' })
export class LoginService {

  user = new BehaviorSubject<User>(null);
  private expireDurationTimer: any;

  constructor(private router: Router) { }
  login(uname: string, password: string) {
    if (uname === environment.userName && password === environment.userPassword) {
      const expireIn = 60 * 60;
      const expireDate = new Date(new Date().getTime() + 1000 * expireIn);
      const user = new User(uname, true, expireDate);
      this.user.next(user);
      this.autoLogout(expireIn * 1000)
      localStorage.setItem('userData', JSON.stringify(user));
      this.router.navigate(['/home'])
    }
  }
  autoLogin() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }
    const loadedUser = new User(userData.uname, userData._valid, new Date(userData._expireDate))
    if (loadedUser.valid) {
      this.user.next(loadedUser);
      const expireDuration = new Date(userData._expireDate).getTime() - new Date().getTime();
      this.autoLogout(expireDuration)
    }
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/'])
    localStorage.removeItem('userData');
    if (this.expireDurationTimer) {
      clearTimeout(this.expireDurationTimer);
    }
    this.expireDurationTimer = null;
  }

  autoLogout(duration) {
    this.expireDurationTimer = setTimeout(() => {
      this.logout();
    }, duration)
  }
}
