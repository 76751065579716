import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SocketioService } from '../socketio.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})

export class DataComponent implements OnInit, AfterViewInit {
  dataAvailable = false;
  mStatus = '';
  sensors = new FormControl();
  sensorList: string[] = ['Temp', 'Hum', 'Air', 'Light'];

  displayedColumns: string[] = ['id', 'status', 'sensor', 'position'];
  dataSource: any;
  waiting = true;
  isError = false;
  resultsLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  matSort: MatSort;
  matPaginator: MatPaginator;
  private dataChangeSub: Subscription;

  constructor(private http: HttpClient, private socketService: SocketioService) {

  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.socketService.machineData);
    this.dataChangeSub = this.socketService.machineDataChanged.subscribe((md: any) => {
      this.dataSource = new MatTableDataSource(md);
      //this.cd.detectChanges();
      this.dataSource.sort = this.matSort;
      this.dataSource.paginator = this.matPaginator;
      this.dataAvailable = true;
      this.waiting = false;
    })
  }

  ngOnDestroy(): void {
    this.dataChangeSub.unsubscribe();
  }
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  ngAfterViewInit() {
    this.matSort = this.sort;
    this.matPaginator = this.paginator;
    this.dataSource.sort = this.matSort;
    this.dataSource.paginator = this.matPaginator;
  }

  applyFilter(filterValue: any) {

    if (typeof (filterValue) === 'object') {
      filterValue = filterValue.join();
    }
    //const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

