<div fxLayout="column wrap" class="home-container">
  <div class="header-container">
    <h1 style="font-size: 3.5rem">Machine Monitoring 4.0</h1>
    <h2 style="font-size: 1.8rem">
      A novel solution with leverage of IoTCrawler to reduce machine downtime
    </h2>
  </div>

  <div class="" style="margin: 120px 0">
    <div style="text-align: center">
      <h1>In collaboration with</h1>
    </div>
    <div style="text-align: center">
      <span style="margin-right: 25px">
        <img
          src="assets/images/iotcrawler-logo.png"
          alt="IoTCrawler"
          height="90"
        />
      </span>
      <span style="margin-right: 25px">
        <img
          #logo
          src="assets/images/dw-logo.png"
          alt="Digital worx"
          height="90"
        />
      </span>
    </div>
  </div>
  <div class="machine-container">
    <div class="center">
      <h1 style="margin-bottom: 40px">Generate insights to</h1>
      <div style="display: flex">
        <div class="box">
          <img
            src="assets/images/maintenance-cost.png"
            alt="Reduce maintenance cost"
            height="100px"
          />

          <h3>Reduce Maintenance Cost</h3>
        </div>
        <div class="box">
          <img
            #logo
            src="assets/images/increased-productivity.png"
            alt="increase Productivity"
            height="100px"
          />
          <h3>Increase Productivity</h3>
        </div>
        <div class="box">
          <img
            #logo
            src="assets/images/predective-maintenance.png"
            alt="predictive maintenance"
            height="100px"
          />
          <h3>Provide Predictive Maintenance</h3>
        </div>
      </div>
    </div>
  </div>
</div>
