<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    #sidenav
    (click)="sidenav.toggle()"
    [ngClass]="{ hidden: (isHandset$ | async) === false }"
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/home">Home</a>
      <a mat-list-item routerLink="/data" *ngIf="isLoggedIn">Data</a>
      <a mat-list-item (click)="onLogout()" *ngIf="isLoggedIn">Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mat-toolbar-title">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span
        ><a routerLink="/home"
          ><img
            #logo
            src="assets/images/logo.png"
            alt="Machine Monitoring"
            height="80" /></a
      ></span>
      <span class="spacer"></span>
      <button mat-button routerLink="/data" type="button" *ngIf="isLoggedIn">
        Data
      </button>
      <button type="button" mat-button *ngIf="isLoggedIn" (click)="onLogout()">
        Logout
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
