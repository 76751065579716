import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('loginForm') loginForm: NgForm;

  isLogin =false;

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
  }
  onSubmit() {
    if (!this.loginForm.valid){
      return;
    }
    const uname = this.loginForm.value.uname;
    const password = this.loginForm.value.password;
    this.loginService.login(uname, password)
    this.loginForm.reset();
  }

}
