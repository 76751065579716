export class User {
  constructor(public uname: string, private _valid: boolean, private _expireDate: Date) {
  }
  get valid() {
    if (!this._expireDate || new Date() > this._expireDate) {
      return false
    }
    return this._valid;
  }
}
