<div class="full-background" fxLayout="row" fxLayoutAlign="center center">
  <div class="box">
    <h1 style="text-align: center">Sign In</h1>
    <form class="example-form" (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <mat-card-content>
        <div style="margin-bottom: 15px">
          <input
            matInput
            placeholder="Username"
            ngModel
            name="uname"
            required
          />
        </div>

        <div style="margin-bottom: 15px">
          <input
            matPassword
            type="password"
            placeholder="Password"
            ngModel
            name="password"
            required
          />
        </div>
      </mat-card-content>
      <button
        mat-raised-button
        type="submit"
        [disabled]="!loginForm.valid"
        style="background-color: #2795d6; color: white"
      >
        Login
      </button>
    </form>
    <div
      style="
        text-align: center;
        color: white;
        margin-bottom: 15px;
        margin-top: 20px;
        size: 1rem;
      "
    >
      <a href="#" style="text-align: center; color: white">
        Register new account
      </a>
    </div>
  </div>
</div>
